/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Pagination, Slider, Collapse, Spin, Dropdown } from 'antd';
import { useLocation } from 'react-router-dom';
import { CruiseItem } from '../components/CruiseItem/CruiseItem';
import SearchComponent from '../components/searchComponent/SearchComponent';
import { useSearchQuery } from '../services/SearchService';
import useAuth from '../hooks/useSession';
import useStore from '../store/userStore';
import { getOptions } from '../helpers/getOptions';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

function SearchResult() {
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const language = getOptions('react_app_cabin_select_api_language');
  const { sessionKey } = useAuth();
  const { setGuests, setPricingData, setTypeCabin, setCabinDataStore, setCabinNumber, options } =
    useStore();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [value, setValue] = useState([0, 20]);
  const [filteredData, setFilteredData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [priceFilter, setPriceFilter] = useState([0, Infinity]);
  const [nameFilter, setNameFilter] = useState('ascending');
  const [dateFilter, setDateFilter] = useState('ascending');
  const [loadingResults, setLoadingResults] = useState(true); // Initially set to true
  const [tempPriceFilter, setTempPriceFilter] = useState([0, Infinity]);

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    setGuests(0);
    setPricingData(null);
    setTypeCabin(null);
    setCabinDataStore({});
    setCabinNumber(null);
  }, []);

  useEffect(() => {
    setLoadingResults(true); // Show the spinner while fetching new data
    setFilteredData([]); // Clear previous search results
    setSortedData([]); // Clear previous sorted data
  }, [priceFilter, nameFilter, dateFilter]); // Watch for changes in filters to fetch new data

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (sessionKey === null || sessionKey === undefined) {
        window.location.reload();
      }
    }, 2500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const {
    data: rearData,
    isFetching,
    isLoading,
    refetch,
    isError,
    status
  } = useSearchQuery({
    session: Cookies.get('sessionKey') || sessionKey,
    language: language,
    nights: searchParams.get('durations'),
    start_date: searchParams.get('start_date'),
    end_date: searchParams.get('end_date'),
    country: searchParams.get('country'),
    ship: searchParams.get('ship'),
    tags: searchParams.get('tags')
  });

  useEffect(() => {
    if (!isFetching && !isLoading) {
      if (rearData == null) {
        setLoadingResults(false); // Hide the spinner when data is fetched successfully
        return;
      }
      if (!rearData) {
        setLoadingResults(false);
      }
    }
    if (isFetching || isLoading) {
      setLoadingResults(true); // Hide the spinner when data is fetched successfully
    }

    if (rearData) {
      let filtered = [...rearData];

      // Apply price filter
      filtered = filtered.filter((item) => {
        const price = getLowestPrice(item);
        return price >= priceFilter[0] && price <= priceFilter[1];
      });

      setFilteredData(filtered);
    }
  }, [rearData, priceFilter, isFetching, isLoading]);

  useEffect(() => {
    if (rearData) {
      const minPrice = Math.min(...rearData.map((item) => getLowestPrice(item)));
      const maxPrice = Math.max(...rearData.map((item) => getLowestPrice(item)));
      setValue([minPrice, maxPrice]);
    }
  }, [rearData]);

  useEffect(() => {
    if (options.react_app_cabin_select_api_username === undefined && !isFetching && !isLoading) {
      refetch();
    }
  }, [options]);
  useEffect(() => {
    setLoadingResults(true);

    if (rearData) {
      let filteredAndSortedData = [...rearData];
      // Apply price filter
      filteredAndSortedData = filteredAndSortedData.filter((item) => {
        const price = getLowestPrice(item);
        return price >= priceFilter[0] && price <= priceFilter[1];
      });
      // Apply name and date filters
      filteredAndSortedData.sort((a, b) => {
        const nameComparison = a.cruise.name.localeCompare(b.cruise.name);
        if (nameFilter === 'ascending') {
          return nameComparison;
        } else {
          return -nameComparison; // Reverse the order for descending
        }
      });

      filteredAndSortedData.sort((a, b) => {
        const dateComparison = new Date(a.embark) - new Date(b.embark);
        if (dateFilter === 'ascending') {
          return dateComparison;
        } else {
          return -dateComparison; // Reverse the order for descending
        }
      });

      setSortedData(filteredAndSortedData);
      setLoadingResults(false);
    }
  }, [rearData, priceFilter, nameFilter, dateFilter]);

  useEffect(() => {
    if (rearData) {
      let asd = rearData.filter((item) => {
        if (
          getLowestPrice(item) >= tempPriceFilter[0] &&
          getLowestPrice(item) <= tempPriceFilter[1]
        ) {
          return item;
        }
      });

      if (asd && asd.length) {
        setSortedData(asd);
      }
    }
  }, [tempPriceFilter]);

  const getLowestPrice = (item) => {
    if (item.markets) {
      const prices = Object.values(item.markets)
        .map((market) => parseFloat(market.price))
        .filter((price) => !isNaN(price) && price !== 0); // exclude NaN and 0 prices

      if (prices.length > 0) {
        const lowestPrice = Math.min(...prices);
        return lowestPrice;
      }
    }
    return 0;
  };

  const showItems = (data) =>
    data.map(
      (item, index) =>
        getLowestPrice(item) > 0 && (
          <CruiseItem
            key={index}
            image={
              (item.cruise.images.length > 0 && item.cruise.images) ||
              (item.ship.images.length > 0 && item.ship.images) || [
                'https://teemingweb.cabinselect.com/wp-content/uploads/sites/4/2023/05/dummy-post-horisontal.jpeg'
              ]
            }
            shipName={item.ship.name}
            cruiseName={item.cruise.name}
            ship={item.ship.name}
            rating={'5'}
            region={item.cruise.countries}
            departure={item.embark}
            price={getLowestPrice(item)}
            currency={item.markets.currency}
            itinerary={item.cruise.itinerary}
            night={item.cruise.nights}
            code={item.code}
            source={item.source}
            description={item?.cruise?.descriptions}
          />
        )
    );

  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  const pageSize = 12;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  return (
    <div className="container p-20 new-design">
      {language && (
        <div>
          <SearchComponent />

          {loadingResults && (
            <>
              {status !== 'fulfilled' && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                  <Spin tip={t('loading')} size="large" />
                </div>
              )}
              {!rearData && !isFetching && <h1>{t('no_result_on_result_page')}</h1>}
            </>
          )}

          {/* {isError && refetch()} */}
          {sortedData.length > 0 && (
            <div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}>
                <h3 style={{ margin: 'auto' }}>{t('your_cruise_results')}</h3>
                <Collapse bordered={false} style={{ width: 400, backgroundColor: '#D9D9D9' }}>
                  <Panel header={<div>{t('filters_and_sort_options')}</div>} key="1">
                    <div>
                      <p
                        style={{
                          marginTop: 12,
                          marginBottom: 12,
                          borderBottomWidth: 1,
                          borderBottomColor: 'white',
                          borderBottomStyle: 'solid',
                          fontSize: 18,
                          width: '100%'
                        }}>
                        {value[0] !== value[1] && (
                          <>
                            {t('filter_by_price')}
                            <Slider
                              range
                              min={value[0]}
                              max={value[1]}
                              onAfterChange={(newTempPriceFilter) =>
                                setTempPriceFilter(newTempPriceFilter)
                              }
                              value={tempPriceFilter}
                            />
                          </>
                        )}
                      </p>
                      <p
                        style={{
                          marginTop: 12,
                          marginBottom: 12,
                          borderBottomWidth: 1,
                          borderBottomColor: 'white',
                          borderBottomStyle: 'solid',
                          fontSize: 18,
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                        onClick={() =>
                          setNameFilter(
                            nameFilter === t('ascending') ? t('descending') : t('ascending')
                          )
                        }>
                        <>
                          {t('NAME')}
                          <span>
                            {nameFilter === t('ascending') ? <span>A-Z</span> : <span>Z-A</span>}
                            {nameFilter === t('ascending') ? (
                              <span>&#9650;</span>
                            ) : (
                              <span>&#9660;</span>
                            )}
                          </span>
                        </>
                      </p>
                      <p
                        style={{
                          marginTop: 12,
                          marginBottom: 12,
                          borderBottomWidth: 1,
                          borderBottomColor: 'white',
                          borderBottomStyle: 'solid',
                          fontSize: 18
                        }}>
                        <span
                          style={{ display: 'flex', justifyContent: 'space-between' }}
                          onClick={() =>
                            setDateFilter(
                              dateFilter === t('ascending') ? t('descending') : t('ascending')
                            )
                          }>
                          {t('DATE_RANGE')}
                          {dateFilter === 'ascending' ? <span>&#9650;</span> : <span>&#9660;</span>}
                        </span>
                      </p>
                    </div>
                  </Panel>
                </Collapse>
              </div>
              <div className="items-container">
                {showItems(sortedData.slice(startIndex, endIndex))}
              </div>
              {sortedData.length && sortedData.length > 12 && (
                <Pagination
                  size="small"
                  total={sortedData.length}
                  pageSize={pageSize}
                  current={currentPage}
                  onChange={onChangePage}
                  style={{ marginTop: 16 }}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export { SearchResult };
