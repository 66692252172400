/* eslint-disable no-unused-vars */ import React from 'react';
import { List, Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';
import useStore from '../../store/userStore';
import './PortsInfo.css';
import { useTranslation } from 'react-i18next';
const { Text, Paragraph } = Typography;
const { Item } = List;

const PortsInfo = () => {
  const { t } = useTranslation();
  const { itinerary, isModalSidebarCardOpened } = useStore();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isInModal = isModalSidebarCardOpened;

  const codeToName = (val) => {
    if (val === 'XX') return '';
    const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
    return regionNamesInEnglish.of(val);
  };

  const renderPortsInfoItem = (item, index) => {
    const { day, port, country, arrive_time, depart_time } = item;
    const countryName = codeToName(country.toUpperCase());

    const isAtSea = port === 'At Sea';
    const shouldRenderCountry = country !== 'XX' && !isAtSea;

    const renderItemText = `Day ${day} - ${port}${shouldRenderCountry ? `, ${countryName}` : ''}`;

    return (
      <Item
        key={index}
        className="border-0 d-flex justify-content-start align-items-start gap-3 ps-0 ports-info-item">
        <Text className="flex-shrink-0" strong>
          {renderItemText}
        </Text>
        <Paragraph className="m-0 ports-info-paragraph">
          {`${t('Port')} - ${port}${
            shouldRenderCountry ? `, Country - ${countryName}` : ''
          }, Arrive Time - ${arrive_time || 'N/A'}, Depart Time - ${depart_time || 'N/A'}`}
        </Paragraph>
      </Item>
    );
  };

  return (
    <>
      <Text strong className="fs-5 text-uppercase">
        {t('Ports of call')}
      </Text>

      {itinerary?.length > 0 && (
        <List
          size="small"
          className="border-0"
          dataSource={itinerary}
          renderItem={renderPortsInfoItem}
        />
      )}
    </>
  );
};

export default PortsInfo;
