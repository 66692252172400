import React, { useEffect } from 'react';
import { Space, Typography } from 'antd';
import styles from './BookingConfirmationPage.module.css';
import BookingInfo from './BookingInfo';
import BookingPassengers from './BookingPassengers';
import BookingItinerary from './BookingItinerary';
import BookingPayment from './BookingPayment';
import useStore from '../../store/userStore';
const { Title, Paragraph } = Typography;
import { useTranslation } from 'react-i18next';
import { getOptions } from '../../helpers/getOptions';
const BookingConfirmationPage = () => {
  const { t } = useTranslation();
  const { cruiseStore, shipImage } = useStore();
  const banner = shipImage;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(cruiseStore);
  return (
    <>
      <div className={styles.banner} style={{ backgroundImage: `url("${banner}")` }}>
        <div className="container-xxl h-100 text-center d-flex justify-content-center align-items-center">
          <div style={{ maxWidth: '780px' }}>
            <Title
              style={{ backgroundColor: 'white', padding: 8, borderRadius: 4 }}
              className={'fs-1'}>
              {t('Booking Confirmation')}
            </Title>
            <Paragraph
              className={'fs-5'}
              style={{ backgroundColor: 'white', padding: 8, borderRadius: 4 }}>
              {t('Congratulations on successfully booking and paying for your cruise')}
            </Paragraph>
          </div>
        </div>
      </div>

      <div className={'mt-5 mb-5 mx-5'}>
        <Space size={[30, 30]} direction={'vertical'} className={'w-100'}>
          <BookingInfo />
          <BookingPassengers />
          <BookingItinerary />
          <BookingPayment />
          {getOptions('REACT_APP_CABIN_SELECT_LANGUAGE') === 'es' && (
            <h2 style={{ textAlign: 'center' }}>
              El pago final del viaje es el 1 de junio de 2024.
            </h2>
          )}
        </Space>
      </div>
    </>
  );
};

export default BookingConfirmationPage;
