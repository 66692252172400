import React from 'react';
import { Typography, Card } from 'antd';
import userStore from '../../../store/userStore';
import { useTranslation } from 'react-i18next';
const { Title, Text } = Typography;

const BookingInfo = () => {
  const { shipName } = userStore();
  const { t } = useTranslation();
  return (
    <Card className={'shadow'} style={{ padding: 24 }}>
      <Title level={2} className={'fs-2 fw-normal'}>
        {t('Welcome aboard')} {shipName}
      </Title>
      <Text className={'fs-4'}>
        <strong>
          {t('On the')} {shipName}...
        </strong>
        {t('Experience the journey of a lifetime on the')} {shipName},{' '}
        {t('where luxury meets adventure')}.{' '}
        {t(
          'Immerse yourself in the allure of the high seas as we set sail to extraordinary destinations.'
        )}{' '}
        {t(
          'Our commitment to excellence ensures that your voyage will be nothing short of spectacular.'
        )}
      </Text>
      <Title level={2} className={'fs-2 fw-bold'}>
        {t('What to expect next:')}
      </Title>
      <Text className={'fs-4'}>
        {'Discover the joy of travel as you anticipate the upcoming experiences.'}{' '}
        {t('From breathtaking views to world-class amenities, the ')}
        {shipName} {'is poised to exceed your expectations.'}{' '}
        {t('Get ready for a seamless blend of comfort and excitement.')}
      </Text>
    </Card>
  );
};

export default BookingInfo;
