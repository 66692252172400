import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getOptions } from '../helpers/getOptions';

const removeNonDigits = (value) => {
  return value.replace(/[^\d]/g, '');
};

export const sessionAPI = createApi({
  reducerPath: 'sessionApi',
  baseQuery: fetchBaseQuery({ baseUrl: getOptions('react_app_cabin_select_pos_domain') }),
  tagTypes: ['Sessions'],
  endpoints: (builder) => ({
    getSession: builder.mutation({
      query: ({ token, agency, agent }) => {
        const urlParams = new URLSearchParams(window.location.search);
        const agencyParam = urlParams.get('agency');
        const agentParam = urlParams.get('agent');

        const cleanedAgencyParam = agencyParam ? removeNonDigits(agencyParam) : null;
        const cleanedAgentParam = agentParam ? removeNonDigits(agentParam) : null;

        console.log(agencyParam, agentParam);
        return {
          url: '/sessions/',
          method: 'POST',
          body: agencyParam
            ? { agency: cleanedAgencyParam, agent: cleanedAgentParam ? agentParam : agent }
            : { agency: agency.agency, agent: agent },
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${token}`
          }
        };
      },
      invalidatesTags: ['Sessions']
    })
  })
});

export const { useGetSessionMutation } = sessionAPI;
