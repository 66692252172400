import React from 'react';
import { Typography, List } from 'antd';
import { formatDate } from '../../helpers/formatDate';
import useStore from '../../store/userStore';
import { useTranslation } from 'react-i18next';
const { Text } = Typography;
const { Item } = List;

function addDaysToDate(dateString, days) {
  const dateParts = dateString.split('-');
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1;
  const day = parseInt(dateParts[2]);
  const date = new Date(year, month, day);

  date.setDate(date.getDate() + days);

  const formattedDate =
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    date.getDate().toString().padStart(2, '0');

  return formattedDate;
}

const DateInfo = () => {
  const { shipName, embark, nights } = useStore();
  const { t } = useTranslation();
  return (
    <List size="small" className={'border-0 p-0'}>
      {shipName && (
        <Item className={'d-flex flex-wrap justify-content-start gap-3 border-0 ps-0'}>
          <Text className={'fw-bold fs-6 text-uppercase'}>{t('ABOARD')}:</Text>
          <Text className={'fs-6 text-uppercase'}>{shipName}</Text>
        </Item>
      )}

      {embark && (
        <Item className={'d-flex flex-wrap justify-content-start gap-3 border-0 ps-0'}>
          <Text className={'fw-bold fs-6 text-uppercase'}>{t('EMBARKATION DATE')}:</Text>
          <Text className={'fs-6 text-uppercase'}>
            {embark ? formatDate(embark) : '00/00/0000'}
          </Text>
        </Item>
      )}

      {embark && nights && (
        <Item
          className={'d-flex flex-wrap justify-content-start gap-3 border-0 ps-0 text-uppercase'}>
          <Text className={'fw-bold fs-6 text-uppercase'}>{t('RETURN DATE')}:</Text>
          <Text className={'fs-6 text-uppercase'}>
            {embark ? formatDate(addDaysToDate(embark, nights)) : '00/00/0000'}
          </Text>
        </Item>
      )}
    </List>
  );
};

export default DateInfo;
