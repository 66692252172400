const setSessionStorageWithOptions = (data) => {
  Object.entries(data).forEach(([key, value]) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  });
};

export const fetchOptions = async (setOptions) => {
  try {
    const domain = window.location.hostname;
    const protocol = window.location.protocol;
    const url = `${protocol}//${domain}/wp-json/acf/v3/options/options`;
    const response = await fetch(url);
    const data = await response.json();
    setSessionStorageWithOptions(data.acf);
    setOptions(data.acf);
    return;
  } catch (error) {
    const {
      REACT_APP_CABIN_SELECT_API_USERNAME: react_app_cabin_select_api_username,
      REACT_APP_CABIN_SELECT_API_PASSWORD: react_app_cabin_select_api_password,
      REACT_APP_CABIN_SELECT_API_AGENCY: react_app_cabin_select_api_agency,
      REACT_APP_CABIN_SELECT_API_AGENT: REACT_APP_CABIN_SELECT_API_AGENT,
      REACT_APP_CABIN_SELECT_API: REACT_APP_CABIN_SELECT_API,
      REACT_APP_CABIN_SELECT_SEARCH_DOMAIN: react_app_cabin_select_search_domain,
      REACT_APP_CABIN_SELECT_TOKEN_DOMAIN: react_app_cabin_select_token_domain,
      REACT_APP_CABIN_SELECT_POS_DOMAIN: react_app_cabin_select_pos_domain,
      REACT_APP_CABIN_SELECT_PAYMENT_DOMAIN: react_app_cabin_select_payment_domain,
      REACT_APP_CABIN_SELECT_API_LANGUAGE: react_app_cabin_select_api_language,
      REACT_APP_CABIN_SELECT_LANGUAGE: REACT_APP_CABIN_SELECT_LANGUAGE,
      REACT_APP_CABIN_SELECT_AUTHORIZE_API_LOGIN_ID: react_app_cabin_select_authorize_api_login_id,
      REACT_APP_CABIN_SELECT_AUTHORIZE_CLIENT_KEY: react_app_cabin_select_authorize_client_key,
      REACT_APP_CABIN_SELECT_TABLE_VIEW: react_app_cabin_select_table_view,
      REACT_APP_CABIN_SELECT_DATE_FORMAT: react_app_cabin_select_date_format,
      REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_MINE: REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_MINE,
      REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_TEST: REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_TEST
    } = process.env;

    const optionsData = {
      react_app_cabin_select_api_username,
      react_app_cabin_select_api_password,
      react_app_cabin_select_api_agency,
      REACT_APP_CABIN_SELECT_API_AGENT,
      react_app_cabin_select_search_domain,
      react_app_cabin_select_token_domain,
      react_app_cabin_select_pos_domain,
      react_app_cabin_select_payment_domain,
      react_app_cabin_select_api_language,
      REACT_APP_CABIN_SELECT_LANGUAGE,
      REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_MINE,
      REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_TEST,
      REACT_APP_CABIN_SELECT_API,
      react_app_cabin_select_authorize_api_login_id,
      react_app_cabin_select_authorize_client_key,
      react_app_cabin_select_table_view,
      react_app_cabin_select_date_format,
      cruise_page: '/',
      footer_background: '#6a9bb5',
      footer_font_color: '#ffffff',
      header_background: '',
      search_app_color: '#1e73be',
      site_logo: false
    };

    setSessionStorageWithOptions(optionsData);
    setOptions(optionsData);
  }
};
