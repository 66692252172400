/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useEffect } from 'react';
import { Card, Button, Typography, Image } from 'antd';
import Slider from '../UI/Slider';
import SelectButton from './SelectButton';
import StateroomTypeCardDetails from './StateroomTypeCardDetails';
import { showCurrency } from '../../helpers/showCurrency.js';
import { fallback } from '../../helpers/fallback.js';
import useStore from '../../store/userStore';
import { useTranslation } from 'react-i18next';
const StateroomTypeCard = ({
  i,
  images,
  headers,
  price,
  value,
  onChange,
  selectedCabin,
  setSelectedCabin
}) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { options: fetchOptions, currency } = useStore();

  const description = value?.descriptions?.[0]?.description || 'No descriptions';
  const data = [
    {
      title: t('Deck'),
      des: value?.decks ? value?.decks[0]?.name : t('no data')
    },
    {
      title: t('Size'),
      des: value?.size
    },
    {
      title: t('Occupancy'),
      des: t('Maximum occupancy ') + value.maximum_occupancy
    },
    ...value?.cabin_attributes.map((attribute) => ({
      title: attribute,
      des: ''
    }))
  ];

  useEffect(() => {
    if (i === 0) {
      setIsCollapsed(false);
    }
  }, []);

  const dataDetails = useMemo(() => data, [data]);

  const onChangeHandler = (id, v) => {
    setSelectedCabin((prev) => !prev);
    onChange(id, v);
  };

  return (
    <div style={{ marginTop: 26 }}>
      <Card
        className={'w-100 border-radius-0'}
        cover={
          <div className={'overflow-hidden'} style={{ height: '300px' }}>
            {images.length > 1 ? (
              <Slider slides={images} height={300} />
            ) : (
              <Image
                width={'100%'}
                height={300}
                src={images[0]}
                style={{ objectFit: 'cover', height: '300px' }}
                fallback={fallback()}
              />
            )}
          </div>
        }>
        <Typography.Title level={2} className={'mb-4'}>
          {headers}
        </Typography.Title>

        <div className={isCollapsed ? 'd-none' : 'd-block'}>
          <Typography.Paragraph>{description}</Typography.Paragraph>
          <StateroomTypeCardDetails data={dataDetails} />
        </div>

        <div className={'d-flex justify-content-start flex-wrap align-items-center gap-3'}>
          <Typography.Text strong className={'fs-1'}>
            {showCurrency(currency, price)}
          </Typography.Text>
          <Button
            type="primary"
            className={'cta-primary'}
            onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ? t('More Info') : t('Less Info')}
          </Button>
          <Button
            type="primary"
            onClick={() => onChangeHandler(i, value)}
            style={
              value.grade === selectedCabin.grade
                ? { backgroundColor: fetchOptions.search_app_color }
                : { backgroundColor: 'white', border: 'solid' }
            }>
            <p
              style={
                value.grade !== selectedCabin.grade
                  ? { color: fetchOptions.search_app_color }
                  : { color: 'white' }
              }>
              {value.grade === selectedCabin.grade ? t('selected') : t('Select')}
            </p>
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default StateroomTypeCard;
