/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import CarouselModal from '../../components/UI/CarouselModal';
import StateroomTypeCard from '../../components/StateroomTypeCard';
import useStore from '../../store/userStore';
import { filterObjectsByKeyValue } from '../../helpers/filterObjectsByKeyValue';
import '../../components/searchComponent/new-design.css';
import { extractCabins } from '../../helpers/extractCabins';
import getGuestCountToObj from '../../helpers/getGuestCountToObj';
import { calcPrice } from '../../helpers/calcPrice';
import { getOptions } from '../../helpers/getOptions';
import { useTranslation } from 'react-i18next';
function PricingContentPage({ data, allCabins, goBack, allPrices, grades }) {
  const TABLE_VIEW = getOptions('react_app_cabin_select_table_view');
  const { t } = useTranslation();
  const location = useLocation();
  const {
    typeCabin,
    setCabinDataStore,
    setPricingData,
    guests,
    setAdults,
    setChildren,
    adults,
    children,
    infants
  } = useStore();
  // const t = getOptions('react_app_cabin_select_table_view');

  // item for dropdown

  const [stateroom, setStateroom] = useState(1);
  const [availableGrade, setAvailableGrade] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  function parseQueryString() {
    let search = location.search.substring(1);
    return JSON.parse(
      '{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
    );
  }
  const cabinsWithCurrentType = filterObjectsByKeyValue(availableGrade, 'meta_category', typeCabin);

  useEffect(() => {
    try {
      let array = [];
      extractCabins(allPrices, grades).map((item) => {
        const target = item[getGuestCountToObj(guests)];
        if (guests === 3) {
          if (item.third !== '0.00') {
            array.push(item);
          }
        }
        if (target > 0) {
          array.push(item);
        }
      });
      setAvailableGrade(array);
    } catch (error) {
      console.log('Error');
    }
  }, [allCabins, grades]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [selectedCabin, setSelectedCabin] = useState(0);
  const [cabineData, setCabineData] = useState(null);

  const handleSelect = (e, v) => {
    setSelectedCabin(v);
    const linkObject = {
      cabine: v,
      decks: data.ship.decks,
      ship: parseQueryString(location.search).code,
      cabin: cabinsWithCurrentType,
      rate_code: v?.rate_code,
      passenger: guests,
      grade: v?.code,
      sailing_code: parseQueryString(location.search).code,
      code: parseQueryString(location.search).code,
      staterooms: stateroom,
      source: parseQueryString(location.search).source
    };
    setCabineData(linkObject);
    setCabinDataStore(linkObject);
    let total_price =
      TABLE_VIEW === 'true'
        ? calcPrice(v, guests, adults, children, infants) / 2
        : calcPrice(v, guests, adults, children, infants);

    setPricingData(total_price);
    // }
  };

  useEffect(() => {
    if (!selectedCabin) {
      if (cabinsWithCurrentType.length >= 1) {
        handleSelect(0, cabinsWithCurrentType[0]);
      }
    }
  }, [selectedCabin, availableGrade]);
  const CabinRender = () => {
    return (
      <div>
        <h5 onClick={() => goBack((prev) => prev - 1)} style={{ padding: 12 }}>
          <button className="cta-secondary">
            {' '}
            {'<'} {t('Choose stateroom type')}
          </button>
        </h5>
        <form>
          {cabinsWithCurrentType
            .sort((a, b) => a[getGuestCountToObj(guests)] - b[getGuestCountToObj(guests)])
            .map((item, index) => {
              const price =
                TABLE_VIEW === 'true'
                  ? item[getGuestCountToObj(guests)] / 2
                  : item[getGuestCountToObj(guests)];

              return (
                <StateroomTypeCard
                  key={index}
                  i={index}
                  selectedCabin={selectedCabin}
                  setSelectedCabin={setSelectedCabin}
                  images={item.images}
                  headers={item.code + ' - ' + item.name}
                  // price={TABLE_VIEW === 'true' ? price / 2 : price}
                  price={price}
                  onChange={handleSelect}
                  value={item}
                />
              );
            })}
        </form>
      </div>
    );
  };

  return (
    <div>
      {data && <CabinRender num={stateroom} />}
      <CarouselModal
        open={isModalOpen}
        images={data.ship.decks.map((item) => item.images[0])}
        onOk={handleOk}
        onCancel={handleCancel}
      />
    </div>
  );
}

export default PricingContentPage;
