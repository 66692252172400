import React from 'react';
import { Typography, Card, Row, Col } from 'antd';
import useStore from '../../../store/userStore';
import countryList from '../../../countries.data';
import { useTranslation } from 'react-i18next';
const { Title, Text } = Typography;

const BookingPassengers = () => {
  const { passengersData, billingData } = useStore();
  const { t } = useTranslation();
  return (
    <Card className={'shadow'} style={{ padding: 24 }}>
      <Title level={2} className={'fs-2 fw-bold'}>
        {t('Passengers')}
      </Title>
      <Row gutter={[30, 40]}>
        {passengersData &&
          passengersData.length > 0 &&
          passengersData.map((passenger, index) => {
            const [year, month, day] = passenger.dob.split('-');

            const date = `${day}/${month}/${year}`;

            return (
              <Col
                key={`passenger-${index}`}
                xs={{ span: 24 }}
                md={{ span: 12 }}
                className={'d-flex flex-wrap justify-content-start align-items-start fw-bold'}>
                <Text className={'fs-5'}>{passenger.title} </Text>
                <Text className={'fs-5'}>{passenger.firstName} </Text>
                <Text className={'fs-5'}>{passenger.surname}</Text>
                <Text className={'fs-5'} style={{ flexBasis: '100%' }}>
                  {date}
                </Text>
              </Col>
            );
          })}

        {billingData && (
          <Col
            flex="1 1 100%"
            className={'d-flex flex-wrap justify-content-start align-items-start fw-bold'}>
            <Text className={'fs-5'}>{billingData.billingAddress}</Text>
            <Text className={'fs-5'}>{billingData.city}</Text>
            <Text className={'fs-5'}>{billingData.state}</Text>
            <Text className={'fs-5'}>{billingData.zipCode}</Text>
            <Text className={'fs-5'}>
              {countryList.find((item) => item.label === billingData.country)}
            </Text>
            <div className={'d-flex justify-content-start'}>
              <Text className={'fs-5'}>{billingData.email}</Text>
              <Text className={'fs-5'}>{billingData.phone}</Text>
            </div>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default BookingPassengers;
