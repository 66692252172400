import create from 'zustand';

const useStore = create((set) => ({
  platform: null,
  searchFrom: {
    country: [],
    tags: []
  },
  cabine: [],
  selectedShipCode: '',
  guests: 0,
  adults: 0,
  children: 0,
  infants: 0,
  shipStore: {},
  cruiseStore: {},
  pricingData: null,
  typeCabin: null,
  passengersData: [],
  cabinData: {},
  options: {
    cruise_page: '/',
    footer_background: '#6a9bb5',
    footer_font_color: '#ffffff',
    header_background: '',
    search_app_color: '#1e73be',
    site_logo: false
  },
  billingData: null,
  isModalSidebarCardOpened: false,
  shipName: null,
  embark: null,
  nights: null,
  itinerary: null,
  stageStateroomsSelect: 2,
  paymentType: 'full',
  cabinsNumber: null,
  currentGrade: null,
  currency: 'USD',
  PNR: null,
  reservationRes: {},
  allPriceData: null,
  continue: true,
  leadGuest: null,
  shipImage: null,

  setPlatform: (data) => set({ platform: data }),
  addCabine: (cabine) => set((state) => ({ cabine: [...state.cabine, cabine] })),
  selectShipCode: (code) => set({ selectedShipCode: code }),
  addShipData: (ship) => set({ shipStore: ship }),
  addCruiseData: (data) => set({ cruiseStore: data }),
  setPricingData: (data) => set({ pricingData: data }),
  setOptions: (data) => set({ options: data }),
  setPath: (path) => set({ path: path }),
  setGuests: (data) => set({ guests: data }),
  setAdults: (data) => set({ adults: data }),
  setChildren: (data) => set({ children: data }),
  setInfants: (data) => set({ infants: data }),
  setTypeCabin: (data) => set({ typeCabin: data }),
  setPassengersData: (data) => set({ passengersData: data }),
  setLeadGuestData: (data) => set({ leadGuest: data }),
  setCabinDataStore: (data) => set({ cabinData: data }),
  setShipName: (data) => set({ shipName: data }),
  setEmbark: (data) => set({ embark: data }),
  setNights: (data) => set({ nights: data }),
  setItinerary: (data) => set({ itinerary: data }),
  setIsModalSidebarCardOpened: (data) => set({ isModalSidebarCardOpened: data }),
  setStageStateroomsSelect: (data) => set({ stageStateroomsSelect: data }),
  setBillingData: (data) => set({ billingData: data }),
  setPaymentType: (data) => set({ paymentType: data }),
  setCabinNumber: (data) => set({ cabinsNumber: data }),
  setSearchForm: (data) => set({ searchFrom: data }),
  setCurrentGrade: (data) => set({ currentGrade: data }),
  setCurrency: (data) => set({ currency: data }),
  setPNR: (data) => set({ PNR: data }),
  setReservationsData: (data) => set({ reservationRes: data }),
  setAllPriceData: (data) => set({ allPriceData: data }),
  setContinue: (data) => set({ continue: data }),
  setShipImage: (data) => set({ shipImage: data })
}));

export default useStore;
