/* eslint-disable no-unused-vars */
import React from 'react';
import { Typography, List } from 'antd';
import useStore from '../../store/userStore';
import { showCurrency } from '../../helpers/showCurrency';
import { formatDate } from '../../helpers/formatDate';
import { useTranslation } from 'react-i18next';
const { Text } = Typography;
const { Item } = List;

const PriceInfo = () => {
  const { pricingData, guests, currency, allPriceData } = useStore();
  const { t } = useTranslation();
  const totalPrice = pricingData;

  const depositPrice = showCurrency(
    currency,
    allPriceData ? allPriceData?.payment_schedule[0]?.amount : null,
    true
  );

  function getDepositDate() {
    return allPriceData?.payment_schedule[0]?.date
      ? formatDate(allPriceData?.payment_schedule[0]?.date)
      : 'Not Available';
  }
  return (
    <List size="small" className={'border-0 p-0'}>
      <Item className={'d-flex flex-wrap gap-3 border-0 ps-0'}>
        <Text className={'fw-bold fs-6 text-uppercase'}>{t('Deposit')}:</Text>
        <Text className={'fw-bold fs-6 text-uppercase'}>{depositPrice || '0.00'}</Text>
      </Item>
      <Item className={'d-flex flex-wrap gap-3 border-0 ps-0 text-uppercase'}>
        <Text className={'fw-bold fs-6 text-uppercase'}>{t('Deposit Date')}:</Text>
        <Text className={'fw-bold fs-6 text-uppercase'}>{getDepositDate()}</Text>
      </Item>
      <Item className={'d-flex flex-wrap gap-3 border-0 ps-0 text-uppercase'}>
        <Text className={'fw-bold fs-6 text-uppercase'}>{t('Total Price')}:</Text>
        <Text className={'fw-bold fs-6 text-uppercase'}>
          {showCurrency(currency, pricingData, true)}
        </Text>
      </Item>
    </List>
  );
};

export default PriceInfo;
