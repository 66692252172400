/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useEffect } from 'react';
import './searchComponent.css';
import './new-design.css';
import { Button, DatePicker, Input, Select, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useSession';
import { Error } from '@mui/icons-material';
import useStore from '../../store/userStore';
import { removeDuplicates } from '../../helpers/removeDuplicates';
import { getOptions } from '../../helpers/getOptions';
import { useTranslation } from 'react-i18next';
// import locale from 'antd/es/date-picker/locale/es_ES';

dayjs.extend(customParseFormat);

import 'dayjs/locale/es';
import Cookies from 'js-cookie';

dayjs.locale('es');
const dateFormat = 'YYYY-MM';

const TAG_COLORS = {
  country: '#db7f7f',
  ship: '#8d91eb',
  cruise_tag: '#85d188'
};

function isCode(str) {
  const lettersOnly = str.replace(/[^A-Za-z]/g, '');
  return /^[A-Z\s/]+$/.test(lettersOnly);
}

const SearchComponent = () => {
  const { t } = useTranslation();
  // get search Params
  const location = useLocation();
  const { options: fetchOptions, searchFrom, setSearchForm } = useStore();

  const { token } = useAuth();
  const searchParams = new URLSearchParams(location.search);
  let paramsSearch = {};
  searchParams.forEach((value, key) => {
    paramsSearch[key] = value;
  });
  // this data for dropdown duration
  const duration = ['1', '3-5', '7', '8-10', '11-14', '14+'];
  // state for chosen data in dropdown duration
  const [durations, setDuration] = useState(paramsSearch.durations ?? t('DURATIONS'));

  // state for data range - have two date like [data, data]
  const [rangeData, setRangeData] = useState([]);
  // this state fore option autocomplete input(Filled in function 'handleSearch' )
  const [options, setOptions] = useState([]);

  // hook fore navigation and throw props
  const navigate = useNavigate();
  const [country, setCountry] = useState(searchFrom?.country);
  const [ship, setShip] = useState([]);
  const [tags, setTags] = useState([]);

  const language = getOptions('react_app_cabin_select_api_language');
  // basics parapets, it is completed in the corresponding functions 'setParams'
  const params = {
    session: Cookies.get('sessionKey'),
    language: language,
    end_date: rangeData.length > 1 && rangeData[1].format(dateFormat)
  };
  let queryString;
  // helpers for the taking current components(see https://ant.design/)
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  // for date range picker
  const customFormat = (value) => `${value.format('MMMM YYYY')}`;

  // function for autocomplete - gets value from <Autocomplete>  onSearch method
  const handleSearch = (value) => {
    let res = [];
    // if field is empty - do nothing
    if (!value || value.length <= 0) {
      res = [];
    } else {
      if (Cookies.get('sessionKey')) {
        const data = sendRequest({
          session: Cookies.get('sessionKey'),
          language: language
        });
        data
          .then((r) => {
            // Then data is loading
            if (r && r.auto_complete_items) {
              // check if data and auto_complete_items exist
              r.auto_complete_items.map((t) =>
                // push item in res array
                res.push({
                  value: t.value,
                  label: t.name,
                  type: t.type
                })
              );
            }
          })
          .finally(() => {
            // finally set res data in to the option for using autocomplete
            setOptions(res);
          });
      }
    }
    // else - create request using 'sendRequest'
  };

  // helper for fetch data gets two params - obj like {key: value} and auto taking auth Token
  const sendRequest = async (params) => {
    try {
      let queryParams = new URLSearchParams(params);

      const response = await fetch(
        getOptions('react_app_cabin_select_search_domain') + 'searchform/?' + queryParams,
        {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.log('There was a problem processing the request: ', error);
    }
  };

  // This is handler then user click on Search btn
  const searchHandler = () => {
    if (rangeData.length > 1) {
      const queryParams = {
        ...(durations !== 'DURATIONS' && {
          durations: printRange(durations)
        }),
        ...(rangeData !== null && {
          start_date: rangeData[0].format(dateFormat),
          end_date: rangeData[1].format(dateFormat)
        }),
        ...(country.length !== 0 && {
          country: getValues(country)
        }),
        ...(ship.length !== 0 && {
          ship: getValues(ship)
        }),
        ...(tags.length !== 0 && {
          tags: getValues(tags)
        })
      };

      const filteredQueryParams = Object.fromEntries(
        Object.entries(queryParams).filter(([, value]) => value != null)
      );

      queryString = new URLSearchParams(filteredQueryParams).toString();

      navigate(`/search-result?${queryString}`, {
        state: {
          params
        }
      });
    } else {
      // if user not set data
    }
  };

  // functions for get user chose
  const searchObj = (value) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].label.includes(value)) {
        return options[i];
      }
    }
    return null;
  };
  const searchObjValue = (value) => {
    if (options.length <= 0) {
      handleSearch(value);
    }
    for (let i = 0; i < options.length; i++) {
      if (options[i].value.includes(value)) {
        return options[i];
      }
    }
    return null;
  };

  // Function for generate night params
  function printRange(input) {
    if (/\d-\d/.test(input)) {
      let nights = [];
      // if input is range
      const [start, end] = input.split('-').map(Number); // get two num
      for (let i = start; i <= end; i++) {
        nights.push(i.toString());
      }

      return nights;
    } else {
      // if just one num
      return input;
    }
  }

  // helper
  function getValues(arr) {
    return removeDuplicates(arr).map((obj) => obj.value);
  }

  // function for set necessary params
  const setParams = () => {
    durations !== 'DURATION' && (params.nights = paramsSearch.durations);
    rangeData !== null && (params.start_date = paramsSearch.startDate);
    rangeData !== null && (params.end_date = paramsSearch.endDate);
    country && (params.country = paramsSearch.country);
    ship && (params.ship = paramsSearch.ship);
    tags && (params.tags = paramsSearch.tags);
  };

  const selectHelper = (obj) => {
    const typeValue = obj.type;
    if (typeValue === 'ship') {
      setShip((prevShips) => {
        return [...new Set([...prevShips, obj])];
      });
    } else if (typeValue === 'country') {
      setCountry((prevCountries) => {
        return [...new Set([...prevCountries, obj])];
      });
    } else if (typeValue === 'cruise_tag') {
      setTags((prevTags) => {
        return [...new Set([...prevTags, obj])];
      });
    }
  };

  function handleDeselect(value) {
    const key = isCode(value) ? 'value' : 'label';

    const shipIndex = ship.findIndex((elem) => elem[key] === value);
    if (shipIndex >= 0) {
      setShip((prevState) => prevState.filter((_, index) => index !== shipIndex));
    } else {
      const countryIndex = country.findIndex((elem) => elem[key] === value);

      if (countryIndex >= 0) {
        setCountry((prevState) => prevState.filter((_, index) => index !== countryIndex));
      } else {
        const tagIndex = tags.findIndex((elem) => elem[key] === value);

        if (tagIndex >= 0) {
          setTags((prevState) => prevState.filter((_, index) => index !== tagIndex));
        }
      }
    }
    setParams();
  }

  // fix not working placeholder data range
  useEffect(() => {
    if (paramsSearch.startDate && paramsSearch.endDate) {
      const start = dayjs(paramsSearch.startDate, dateFormat).startOf('month');
      const end = dayjs(paramsSearch.endDate, dateFormat).endOf('month');
      setRangeData([start, end]);
    }
  }, [paramsSearch.startDate, paramsSearch.endDate]);

  function getParams(country, ship, tags) {
    if (!country && !ship && !tags) {
      return [];
    }
    const params = [];

    if (country !== undefined && country !== null && country !== '') {
      params.push(...country.split(','));
    }

    if (ship !== undefined && ship !== null && ship !== '') {
      params.push(...ship.split(','));
    }

    if (tags !== undefined && tags !== null && tags !== '') {
      params.push(...tags.split(','));
    }

    return params;
  }

  useEffect(() => {
    // handleSearch('p');

    if (searchParams.has('durations')) {
      paramsSearch.durations = searchParams.get('durations');
    }
    if (searchParams.has('start_date')) {
      paramsSearch.startDate = searchParams.get('start_date');
    }
    if (searchParams.has('end_date')) {
      paramsSearch.endDate = searchParams.get('end_date');
    }
    if (searchParams.has('country')) {
      paramsSearch.country = searchParams.get('country');
    }
    if (searchParams.has('ship')) {
      paramsSearch.ship = searchParams.get('ship');
    }
    if (searchParams.has('tags')) {
      paramsSearch.tags = searchParams.get('tags');
    }
  }, [location.search]);

  useEffect(() => {
    if (searchFrom.country.length) {
      setCountry(searchFrom.country);
    }

    if (searchFrom.tags.length) {
      setTags(searchFrom.tags);
    }
  }, []);

  useEffect(() => {
    if (country.length || tags.length) {
      setSearchForm({
        country: country.length ? country : searchFrom.country,
        tags: tags.length ? tags : searchFrom.tags
      });
    }
  }, [country, tags]);

  return (
    <div>
      <Input.Group className="d-flex justify-content-between search-container">
        <Select
          placeholder={t('multiply_placeholder')}
          mode="multiple"
          className="mobile-field"
          allowClear
          // locale={locale}
          tagRender={(props) => {
            const { label, value, closable, onClose } = props;
            if (!label || !value || !closable || !onClose) {
              return null;
            }

            return (
              <Tag
                color={
                  TAG_COLORS[searchObj(value)?.type]
                    ? TAG_COLORS[searchObj(value)?.type]
                    : TAG_COLORS[searchObjValue(value)?.type]
                }
                closable={closable}
                onClose={onClose}>
                {searchObjValue(value)?.label == undefined
                  ? searchObj(value)?.label
                  : searchObjValue(value)?.label}
              </Tag>
            );
          }}
          style={{
            width: '50%',
            paddingRight: 0
          }}
          defaultValue={getParams(paramsSearch.country, paramsSearch.ship, paramsSearch.tags)}
          fieldNames={{ label: 'label', value: 'label', type: 'type' }}
          onSearch={(v) => handleSearch(v)}
          onSelect={(value) => selectHelper(searchObj(value))}
          options={options}
          onDeselect={(v) => handleDeselect(v)}
        />
        <RangePicker
          className="mobile-field"
          picker="month"
          separator={<></>}
          value={rangeData}
          defaultValue={[
            dayjs(paramsSearch.startDate, dateFormat).startOf('month'),
            dayjs(paramsSearch.endDate, dateFormat).endOf('month')
          ]}
          onChange={(value) => setRangeData(value)}
          prevIcon={<></>}
          disabledDate={(current) => current && current.isBefore(dayjs(), 'month')}
          format={customFormat}
          style={{ marginRight: 8, width: '35%' }}
          // locale={locale}
          // placeholder={[t('start_month'), t('end_month')]}
        />
        <Select
          // locale={locale}
          className="mobile-field"
          defaultValue={durations}
          onChange={(value) => setDuration(value)}
          style={{ width: '15%' }}>
          {duration.map((item, index) => (
            <Option value={item} key={index}>
              {item}
            </Option>
          ))}
        </Select>
        <Button
          type="default"
          style={{
            backgroundColor: fetchOptions.search_app_color,
            color: 'white',
            textTransform: 'uppercase'
          }}
          onClick={() => searchHandler()}
          className="search-button w-25 mobile-button">
          {t('search')}
        </Button>
      </Input.Group>
      <br />
    </div>
  );
};

export default SearchComponent;
