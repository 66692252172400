import { apiSearch } from './apiSearch';
import { getOptions } from '../helpers/getOptions';

const API_BASE_URL =
  getOptions('react_app_cabin_select_search_domain') || 'https://devsearchapi.cabinselect.com';

export const holdCabinAPI = apiSearch.injectEndpoints({
  endpoints: (builder) => ({
    holdCabin: builder.mutation({
      query: ({ sailing_code, source, session, rate_code, grade_code, cabin_number }) => {
        if (source === 'gcg') {
          return {};
        }
        return {
          url: `${API_BASE_URL}/holdcabin/${sailing_code}/${source}/`,
          body: {
            session,
            sailing_code,
            rate_code,
            grade_code,
            cabin_number
          },
          method: 'POST'
        };
      },
      invalidatesTags: ['HoldCabin']
    })
  }),
  overrideExisting: true
});

export const { useHoldCabinMutation } = holdCabinAPI;
