import React, { useMemo, useState } from 'react';
import { Card, Modal } from 'antd';
import useStore from '../../store/userStore';
import '../searchComponent/new-design.css';
import { useTranslation } from 'react-i18next';
const SidebarCard = ({ title, children }) => {
  const { t } = useTranslation();
  const { setIsModalSidebarCardOpened } = useStore();
  const [isModalOpened, setIsModalOpened] = useState(false);

  const handleOpen = (e) => {
    e.preventDefault();
    setIsModalSidebarCardOpened(true);
    setIsModalOpened(true);
  };

  const handleClose = () => {
    setIsModalSidebarCardOpened(false);
    setIsModalOpened(false);
  };

  const titleElem = <h2 className={'text-uppercase'}>{title}</h2>;

  const cardMemo = useMemo(() => {
    return (
      <Card
        className={'shadow new-design'}
        title={titleElem}
        extra={
          <a href="#" onClick={handleOpen} className={'cta-secondary cta-secondary__itin'}>
            {t('View more')}
          </a>
        }
        headStyle={{ border: 'none' }}
        bodyStyle={{ paddingTop: 0 }}
        bordered={false}>
        {children}
      </Card>
    );
  }, [title]);

  return (
    <>
      {cardMemo}
      <Modal
        title={titleElem}
        centered
        open={isModalOpened}
        footer={false}
        onCancel={handleClose}
        className={'w-100'}
        style={{ maxWidth: '1000px', zIndex: 'var(--zIndexItineraryModal)' }}>
        {children}
      </Modal>
    </>
  );
};

export default React.memo(SidebarCard);
