/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { Select, Card, message, Typography } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCruiseDetailQuery, useGetCabinsQuery, isFetching } from '../services/SearchService';
import CarouselImage from '../components/CarouselImage';
import useStore from '../store/userStore';
import { getRelativePath } from '../helpers/getRelativePath';
const { Option } = Select;
import Cookies from 'js-cookie';
import { Button, Empty } from 'antd';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import { FormContinue } from './stateroom/FormContinue';
import Slider from '../components/UI/Slider';
import '../components/searchComponent/new-design.css';
import { useHoldCabinMutation } from '../services/HoldCabinService';
import { filterObjectsByKeyValue } from '../helpers/filterObjectsByKeyValue';
import { useTranslation } from 'react-i18next';

const Deckplans = () => {
  const { t } = useTranslation();
  const sessionKey = Cookies.get('sessionKey');
  const path = ['Home', 'Result', 'Staterooms', 'Cabins'];
  const { options: fetchOptions, cabinData, setCabinNumber } = useStore();
  const location = useLocation();
  const searchParams = Object.fromEntries(new URLSearchParams(location.search).entries());
  const navigate = useNavigate();
  const decksData = location.state.decks;
  const stateroom = location.state.staterooms;
  const [selectedStaterooms, setStaterooms] = useState([]);
  const getFirst = () => {
    const firstObjectWithImages = decksData.find(
      (deck) => Array.isArray(deck.images) && deck.images.length > 0
    );

    return firstObjectWithImages?.name;
  };
  const [deck, setDeck] = useState(getFirst());
  const [messageApi, contextHolder] = message.useMessage();
  const [stepSelection, setStepSelection] = useState(5);
  const sc = location.state.sailing_code;
  const source = location.state.source;

  const { data, isFetching: isCruiseDetailFetching } = useCruiseDetailQuery({
    session: sessionKey,
    sailing_code: location.state.code,
    source: source
  });

  const {
    data: cabins,
    isFetching: isCabinsFetching,
    error: errorToLoadCabins
  } = useGetCabinsQuery({
    sailing_code: location.state.code,
    session: sessionKey,
    source: source,
    rate_code: location.state.rate_code,
    grade_code: location.state.grade,
    guest_count: location.state.passenger
  });

  const [get_hold_cabin, { data: hold_cabin }] = useHoldCabinMutation();

  const createHoldCabin = async () => {
    if (
      sc.length !== 0 &&
      source.length !== 0 &&
      sessionKey &&
      location.state.rate_code &&
      location.state.grade &&
      selectedStaterooms[0]?.number
    ) {
      const req = await get_hold_cabin({
        sailing_code: sc,
        source: source,
        session: sessionKey,
        rate_code: location.state.rate_code,
        grade_code: location.state.grade,
        cabin_number: selectedStaterooms[0]?.number
      });
    }
  };

  useEffect(() => {
    if (selectedStaterooms.length !== 0) {
      createHoldCabin();
    }
  }, [selectedStaterooms]);

  const bookHandler = () => {
    if (selectedStaterooms.length !== stateroom) {
      messageApi.open({
        type: 'warning',
        content: `Please, choose ${stateroom} cabins`
      });
      return;
    } else {
      const params = new URLSearchParams({
        ship: location.state.code,
        passenger: location.state.passenger,
        staterooms: stateroom
      });

      navigate(
        `${''}/upsell?${params.toString()}&source=${location.state.source}&rate_code=${
          location.state.rate_code
        }`,
        {
          state: {
            decks: data.ship.decks
          }
        }
      );
    }
  };

  useEffect(() => {
    console.log(errorToLoadCabins);
    if (errorToLoadCabins?.originalStatus === 500) {
      messageApi.open({
        type: 'error',
        content: errorToLoadCabins?.error
      });
      return;
    }
  }, [errorToLoadCabins]);

  const handleSelect = useCallback(
    (item) => {
      setCabinNumber(item);
      setStaterooms((prevSelectedStaterooms) => {
        const index = prevSelectedStaterooms.findIndex(
          (selected) => selected.number === item.number
        );
        if (index !== -1) {
          return [...prevSelectedStaterooms, item];
        } else if (prevSelectedStaterooms.length < stateroom) {
          return [...prevSelectedStaterooms, item];
        } else {
          return [...prevSelectedStaterooms.slice(1), item];
        }
      });
    },
    [setStaterooms, stateroom]
  );

  const handleRemove = useCallback(
    (item) => {
      setStaterooms((prevSelectedStaterooms) =>
        prevSelectedStaterooms.filter((selected) => selected.number !== item.number)
      );
    },
    [setStaterooms]
  );

  useEffect(() => {
    if (cabins && cabins.length > 0) {
      handleSelect(cabins[0]); // Select the first cabin by default
    }
  }, [cabins, handleSelect]);

  useEffect(() => {
    console.log(cabinData);
  }, [cabinData]);

  const redirectTimeout = setTimeout(() => {
    if (!data) {
      navigate(`/stateroom?code=${searchParams.ship}&source=${source}`, {
        state: {
          code: searchParams.ship
        }
      });
    }
  }, 1500);
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => clearTimeout(redirectTimeout);
  }, [data]);

  const ClassDetails = () => {
    return (
      <div>
        <Card
          className={'w-100 border-radius-0'}
          cover={
            <div className={'overflow-hidden'} style={{ height: '300px' }}>
              <Slider slides={cabinData.cabine?.images} />
            </div>
          }>
          <Typography.Title level={2} className={'mb-4'}>
            {cabinData.cabine?.name}
          </Typography.Title>
          <div>
            {cabinData?.cabine?.descriptions?.[0]?.description && (
              <p>{cabinData.cabine.descriptions[0].description}</p>
            )}
            {cabinData.cabine?.cabin_attributes && cabinData.cabine.cabin_attributes.length > 0 && (
              <p>
                {cabinData.cabine.cabin_attributes
                  .map((item) => {
                    if (item && item.name) {
                      return `${item.name}, `;
                    }
                    return null; // Return null for undefined items
                  })
                  .join('')}
              </p>
            )}
          </div>
        </Card>
      </div>
    );
  };

  return (
    <>
      <div className="container-xxl new-design ">
        {contextHolder}
        <Breadcrumbs paths={path} />
        {isCruiseDetailFetching || isCabinsFetching ? (
          <div>{t('loading')}...</div>
        ) : (
          data && (
            <div>
              <div className="row container-deck">
                <div className="col-7 flex cabins mobile-100">
                  {/*<ShipBaner shipImage={data?.ship?.images[0]} shipName={data?.cruise?.name} />*/}
                  <ClassDetails />
                  {cabins ? (
                    cabins.map((item, index) => (
                      <div
                        key={index}
                        className={
                          selectedStaterooms.some((selected) => selected.number === item.number)
                            ? 'selected'
                            : ''
                        }>
                        <div>
                          <h3
                            style={
                              selectedStaterooms.some((selected) => selected.number === item.number)
                                ? { color: '' }
                                : { color: '' }
                            }>{`${t('Cabin')} ${item.number}`}</h3>

                          {selectedStaterooms.some(
                            (selected) => selected.number === item.number
                          ) ? (
                            <button
                              style={{
                                float: 'right',
                                backgroundColor: fetchOptions.search_app_color
                              }}
                              onClick={() => handleRemove(item)}>
                              {t('Remove')}
                            </button>
                          ) : (
                            <button
                              style={{
                                float: 'right',
                                backgroundColor: fetchOptions.search_app_color
                              }}
                              onClick={() => handleSelect(item)}>
                              {t('Select')}
                            </button>
                          )}
                        </div>
                        <p
                          style={
                            selectedStaterooms.some((selected) => selected.number === item.number)
                              ? { color: 'white' }
                              : { color: 'black' }
                          }>
                          {item.location && `Location - ${item.location}. `}
                          {item.maximum_occupancy &&
                            `Maximum occupancy - ${item.maximum_occupancy}. `}
                          {item.minimum_occupancy &&
                            `Minimum occupancy - ${item.minimum_occupancy}. `}
                          {item.position && `Position - ${item.position}. `}
                          {item.size && `Size - ${item.size}. `}
                        </p>
                      </div>
                    ))
                  ) : (
                    <Empty
                      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                      imageStyle={{
                        height: 60
                      }}
                      description={<span>No cabins of this type available</span>}>
                      <Button
                        style={{
                          float: 'right',
                          backgroundColor: fetchOptions.search_app_color
                        }}
                        onClick={() => navigate(-1)}>
                        GO BACK
                      </Button>
                    </Empty>
                  )}
                </div>
                <div className="col-4 new-design deck-plans mobile-100">
                  <div
                    style={{
                      textAlign: 'center',
                      paddingTop: 24,
                      borderRadius: 8,
                      backgroundColor: 'gray'
                    }}>
                    <h2 color="#747474" style={{ fontWeight: 700, textTransform: 'uppercase' }}>
                      {t('Deck plans')}
                    </h2>
                    <Select
                      defaultValue={deck}
                      onChange={(value) => setDeck(value)}
                      style={{ width: '80%', textAlign: 'left' }}>
                      {decksData.map((item, index) => {
                        if (item?.images) {
                          if (item?.images?.length >= 1) {
                            return (
                              <Option value={item.name} key={index} textAlign={'left'}>
                                {item.name}
                              </Option>
                            );
                          }
                        }
                      })}
                    </Select>
                    <CarouselImage
                      images={
                        decksData && filterObjectsByKeyValue(decksData, 'name', deck)[0]?.images
                      }
                      allData={decksData && decksData}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <FormContinue
        nextStep={setStepSelection}
        step={stepSelection}
        goToChooseCabin={bookHandler}
      />
    </>
  );
};

export { Deckplans };
