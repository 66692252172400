import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getOptions } from '../helpers/getOptions';

export const authAPI = createApi({
  reducerPath: 'authAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: getOptions('REACT_APP_CABIN_SELECT_API')
      ? getOptions('REACT_APP_CABIN_SELECT_API')
      : 'https://api.cabinselect.com/'
  }),
  tagTypes: ['Auth'],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (payload) => ({
        url: '/api/token/',
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      }),
      invalidatesTags: ['Auth']
    })
  })
});

export const { useLoginMutation } = authAPI;
