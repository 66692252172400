import React, { useState } from 'react';
import ButtonPrimary from '../../components/UI/ButtonPrimary';
import { Row, Col } from 'antd';
import useStore from '../../store/userStore';
import { useNavigate } from 'react-router-dom';
import '../../components/searchComponent/new-design.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShip, faUsers } from '@fortawesome/free-solid-svg-icons';
import { showCurrency } from '../../helpers/showCurrency';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const FormContinue = ({ nextStep, step, goToChooseCabin, allCabins, tableView }) => {
  const { t } = useTranslation();
  const {
    guests,
    pricingData,
    typeCabin,
    cabinData,
    cabinsNumber,
    currency,
    continue: next
  } = useStore();
  const navigate = useNavigate();

  const [showPrice, setShowPrice] = useState(pricingData);

  const onClickHandler = () => {
    if (step === 3) {
      if (tableView === 'true') {
        navigate(
          `/cabin?sourse=${cabinData.source}&rate_code=${cabinData.rate_code}&grade_code=${cabinData.cabine.grade}&guest_count=${guests}&ship=${cabinData.ship}`,
          {
            state: {
              cabine: cabinData.cabine,
              decks: cabinData.decks,
              ship: cabinData.ship,
              cabin: cabinData.cabin,
              rate_code: cabinData.rate_code,
              passenger: guests && guests,
              grade: cabinData.cabine.grade,
              sailing_code: cabinData.sailing_code,
              code: cabinData.code,
              staterooms: cabinData.staterooms,
              source: cabinData.source
            }
          }
        );
      } else {
        nextStep(4);
      }
    }
    if (step === 2) {
      nextStep(3);
    }
    if (step === 4) {
      navigate(
        `/cabin?sourse=${cabinData.source}&rate_code=${cabinData.rate_code}&grade_code=${cabinData.cabine.grade}&guest_count=${guests}&ship=${cabinData.ship}`,
        {
          state: {
            cabine: cabinData.cabine,
            decks: cabinData.decks,
            ship: cabinData.ship,
            cabin: cabinData.cabin,
            rate_code: cabinData.rate_code,
            passenger: guests && guests,
            grade: cabinData.cabine.grade,
            sailing_code: cabinData.sailing_code,
            code: cabinData.code,
            staterooms: cabinData.staterooms,
            source: cabinData.source
          }
        }
      );
    }
    if (step === 5) {
      goToChooseCabin();
    }
  };

  function findObjectWithSmallestStandard(objects) {
    if (!Array.isArray(objects) || objects.length === 0) {
      return null;
    }

    let smallestStandard = parseFloat(objects[0].standard);
    let smallestObject = objects[0];

    for (let i = 1; i < objects.length; i++) {
      const currentStandard = parseFloat(objects[i].standard);

      if (!isNaN(currentStandard) && currentStandard < smallestStandard) {
        smallestStandard = currentStandard;
        smallestObject = objects[i];
      }
    }

    return smallestObject;
  }

  const smallerCabin = findObjectWithSmallestStandard(allCabins);
  const stateroom = typeCabin == 'standard' ? smallerCabin?.meta_category : typeCabin;
  const cabinClass = cabinData?.cabine?.name;

  useEffect(() => {
    setShowPrice(pricingData);
  }, [pricingData]);

  return (
    <div className="details-form-continue new-design" style={{ marginTop: 120 }}>
      <Row justify="space-between" align="middle">
        <Col span={12}>
          <Row gutter={[16, 16]} align="middle" justify="center">
            <Col
              span={12}
              className="details-form-container-guests"
              align="middle"
              justify="center">
              <p>
                <FontAwesomeIcon icon={faShip} className="icon" />
              </p>
              <p style={{ textTransform: 'capitalize' }}>
                {t('Staterooms')}: {cabinsNumber && cabinsNumber.number}
              </p>
              <p style={{ textTransform: 'capitalize' }}>
                {cabinClass ? cabinClass : stateroom} (1)
              </p>
            </Col>
            <Col
              span={12}
              className="details-form-container-guests"
              align="middle"
              justify="center">
              <p>
                <FontAwesomeIcon icon={faUsers} className="icon" />
              </p>
              <p>{t('Guests')}:</p>
              <p>{guests}</p>
            </Col>
          </Row>
        </Col>
        <Row
          span={8}
          style={{ textAlign: 'right' }}
          justify="center"
          align="middle"
          className="text-center details-form-total-inner">
          <div>
            <p>
              {t('Total price')}: {showCurrency(currency, showPrice, true)}
            </p>
            <ButtonPrimary
              className={'btn-continue-form'}
              onClick={() => {
                next && onClickHandler();
              }}>
              {t('CONTINUE')}
            </ButtonPrimary>
          </div>
        </Row>
      </Row>
    </div>
  );
};
